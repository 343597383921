

























































































































































import SelectBoolean, {
  ValueType as BooleanType,
} from "@/components/custom/select/SelectBoolean.vue";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectDealer from "@/components/custom/select/SelectDealer.vue";
import SelectLessor from "@/components/custom/select/SelectLessor.vue";
import SelectMasterType from "@/components/custom/select/SelectMasterType.vue";
import { DisplayBoolean } from "@/components/DisplayBoolean";
import { SelectLeasing } from "@/components/Leasing";
import { APagination, useBlob, useLeasing } from "@/hooks";
import { FormFilter } from "@/hooks/useLeasing";
import MNotificationVue from "@/mixins/MNotification.vue";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { LeasingStateEnum } from "@/models/enums/Leasing.enum";
import { ListContactDataDTO } from "@interface/ContactDataDto.interface";
import { RequestQueryParamsModel } from "@interface/http.interface";
import { ListLeasingHeaderDto } from "@interface/leasing";
import { DataWarehouseBranch } from "@interface/logistic.interface";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Ref } from "vue-property-decorator";

@Component({
  components: {
    DisplayBoolean,
    SelectBoolean,
    SelectBranch,
    SelectDealer,
    SelectLeasing,
    SelectLessor,
    SelectMasterType,
  },
})
export default class IndexPage extends Mixins(MNotificationVue) {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  @Ref("formModel")
  formModel!: FormModel;

  pagination = {
    page: FIRST_PAGE,
    limit: DEFAULT_PAGE_SIZE,
  };

  selectedRowKeys: Array<string> = [];

  form: FormFilter = {
    branchName: "",
    branchId: "",
    dealerId: "",
    dealerName: "",
    lessorId: "",
    lessorName: "",
    leasingContractDate: [],
    leasingNumber: "",
    leasingNumberId: "",
    paidOff: "" as BooleanType,
    leasingContractNumber: "",
    status: "",
    reset() {
      Object.keys(this).forEach(e => {
        this[e] = "";
        if (e === "leasingContractDate") this[e] = [];
      });
    },
  };

  loading = {
    find: false,
    download: false,
  };

  columns = [
    {
      title: this.$t("lbl_leasing_contract_number"),
      dataIndex: "leasingContractNumber",
      width: 250,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_lessor_name"),
      dataIndex: "lessorName",
      width: 250,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_leasing_number"),
      dataIndex: "leasingNumber",
      width: 170,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_leasing_contract_date"),
      dataIndex: "leasingContractDate",
      width: 170,
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_paid_off"),
      dataIndex: "paidOff",
      width: 170,
      scopedSlots: { customRender: "boolean" },
    },
    {
      title: this.$t("lbl_paid_off_date"),
      dataIndex: "paidOffDate",
      width: 170,
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      width: 170,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_action"),
      key: "operation",
      scopedSlots: { customRender: "operation" },
      width: 120,
      align: "center",
    },
  ];

  dataLeasing: Pagination<ListLeasingHeaderDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  handleFind(): void {
    this.pagination.page = FIRST_PAGE;
    const params = this.buildQueryParams();
    this.fetchData(params);
  }

  buildQueryParams(): RequestQueryParamsModel {
    const params = new RequestQueryParams();
    const { filterBy } = useLeasing();
    params.search = filterBy({ ...this.form, leasingNumberId: "" });
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;
    params.sorts = "createdDate:asc";
    return params;
  }

  async fetchData(params?: RequestQueryParamsModel): Promise<void> {
    const { findAll } = useLeasing();
    try {
      this.loading.find = true;
      const res = await findAll(params);
      this.dataLeasing = res;
    } finally {
      this.loading.find = false;
    }
  }

  async handleDownload(): Promise<void> {
    const { download, mapFormFilterToParamDownload } = useLeasing();
    const { toDownload } = useBlob();
    try {
      const req = mapFormFilterToParamDownload(this.form, this.selectedRowKeys);
      this.loading.download = true;
      const res = await download(req);
      toDownload(res, "leasing_card.xlsx");
    } finally {
      this.loading.download = false;
    }
  }

  onTableChange(pagination: APagination): void {
    const { current, pageSize } = pagination;

    this.pagination.page = current;

    this.pagination.page =
      pageSize !== this.pagination.limit ? FIRST_PAGE : current;
    this.pagination.limit = pageSize;

    const params = this.buildQueryParams();
    this.fetchData(params);
  }

  onChangeBranch(e?: Option<DataWarehouseBranch>): void {
    this.form.branchId = e?.meta?.id || "";
    this.form.branchName = e?.label || "";
  }

  onChangeDealer(e?: Option<ListContactDataDTO>): void {
    this.form.dealerId = e?.meta?.id || "";
    this.form.dealerName = e?.label || "";
  }

  onChangeLessor(e?: Option<ListContactDataDTO>): void {
    this.form.lessorId = e?.meta?.id || "";
    this.form.lessorName = e?.label || "";
  }

  onChangeLeasing(e?: Option<ListLeasingHeaderDto>): void {
    this.form.leasingNumber = e?.label || "";
    this.form.leasingNumberId = e?.meta?.id || "";
  }

  handleReset(): void {
    this.formModel.resetFields();
    this.form.reset();
  }

  getRoute(status: LeasingStateEnum): string {
    const editRoute = "account-payables.leasing.edit";
    const detailRoute = "account-payables.leasing.detail";
    const STATUS_TO_EDIT_ROUTE = [
      LeasingStateEnum.DRAFT.toUpperCase(),
      LeasingStateEnum.SUBMITTED.toUpperCase(),
    ];

    if (STATUS_TO_EDIT_ROUTE.includes(status.toUpperCase())) {
      return editRoute;
    }

    return detailRoute;
  }

  onSelectChange(selectedRowKeys: Array<string>): void {
    this.selectedRowKeys = selectedRowKeys;
  }
}
