

















import { Option } from "@/models/class/option.class";
import { Component, Prop, Vue } from "vue-property-decorator";

const ValueEnum = ["N", "Y"] as const;
export type ValueType = (typeof ValueEnum)[number];

@Component
export default class SelectBoolean extends Vue {
  @Prop({ required: false, default: "", type: String })
  value!: string;

  options: Array<Option> = [
    {
      key: 0,
      label: "lbl_yes",
      value: "Y" as ValueType,
    },
    {
      key: 1,
      label: "lbl_no",
      value: "N" as ValueType,
    },
  ];

  onChange(e: ValueType): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }
}
