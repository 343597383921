var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: { value: _vm.value, "allow-clear": "" },
      on: { change: _vm.onChange }
    },
    _vm._l(_vm.options, function(data) {
      return _c(
        "a-select-option",
        { key: data.key, attrs: { value: data.value } },
        [
          _c("a-tooltip", [
            _c("span", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")
            ]),
            _vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")
          ])
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }